import React, { useCallback, useState, useEffect } from "react";
import moment from "moment";
import {
  DataView,
  stringColumn,
  dateColumn,
} from "@fenix/core/controls/DataView/DataView";
import { RowActions } from "@fenix/core/controls/DataView/Actions";
import { CloneEditConfiguration } from "./CloneEditConfiguration";
import { getConfiguration, deleteConfiguration } from "./configuration.api";
import { useSnackbar } from "notistack";

const Configuration = () => {
  const deleteConfigItem = useCallback(async (item) => {
    await deleteConfiguration(item.id);
  }, []);

  return (
    <DataView
      title="KONFIGURACJA"
      columns={[
        { field: "id", hide: true, filterable: false },
        { field: "name", headerName: "Nazwa", ...stringColumn, flex: 2 },
        { field: "value", headerName: "Wartość", ...stringColumn, flex: 2 },
        {
          field: "validFrom",
          headerName: "Ważne od",
          ...dateColumn,
          flex: 0.5,
        },
        { field: "notes", headerName: "Notatki", ...stringColumn, flex: 1 },
      ]}
      actions={[
        {
          type: "rowMulti",
          flex: 0.5,
          label: "Akcje",
          actions: [
            RowActions.Clone(
              "Klonowanie konfiguracji",
              (arg) => <CloneEditConfiguration {...arg} clone={true} />,
              "Dashboard/Configuration/Clone"
            ),
            RowActions.Edit(
              "Edycja konfiguracji",
              (arg) => <CloneEditConfiguration {...arg} clone={false} />,
              "Dashboard/Configuration/Edit"
            ),
            RowActions.Delete(
              "Usunięcie konfiguracji",
              (item) =>
                `Czy na pewno chcesz usunąć konfigurację '${
                  item.name
                }' ważną od ${moment(item.validFrom).format("YYYY-MM-DD")}?`,
              deleteConfigItem,
              "Dashboard/Configuration/Delete"
            ),
          ],
        },
      ]}
      loadData={(filter, sort, limit) => getConfiguration(filter, sort, limit)}
      initialSort={[
        { field: "name", sort: "asc" },
        { field: "validFrom", sort: "asc" },
      ]}
    ></DataView>
  );
};

export default Configuration;
