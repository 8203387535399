import {
  getData,
  deleteData,
  postData,
  putData,
} from "@fenix/core/services/api";

export const getConfiguration = async (filter, sort, limit) =>
  await getData("/configuration", { filter, sort, limit });

export const getConfigurationItem = async (id) =>
  await getData(`/configuration/${id}`);

export const cloneConfiguration = async (data) =>
  await postData(`/configuration/${data.id}/clone`, data);

export const updateConfiguration = async (data) =>
  await putData(`/configuration/${data.id}`, data);

export const deleteConfiguration = async (id) =>
  await deleteData(`/configuration/${id}`);
