import React from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Grid } from "@mui/material";


import { FormTextField } from "@fenix/core/controls/FormTypes/FormTextField";
import { FormDatePicker } from "@fenix/core/controls/FormTypes/FormDatePicker";
import { LoaderWrapper } from "@fenix/core/components/LoaderWrapper/LoaderWrapper";
import { isNotEmpty, onInvalidForm } from "@fenix/core/helpers/tools";
import { FormPopup } from "@fenix/core/components/Popup/FormPopup";

import {
  getConfigurationItem,
  cloneConfiguration,
  updateConfiguration,
} from "./configuration.api";

export const CloneEditConfiguration = ({ popup, item, clone }) => {
  const [config, setConfig] = React.useState(null);

  React.useEffect(() => {
    (async () => setConfig(await getConfigurationItem(item.id)))();
  }, []);

  const onSubmit = async (data) => {
    let modified = { ...config, ...data };
    modified.validFrom = modified.validFrom.format();

    if (modified.value == "") {
      modified.value = null;
    }

    const result = clone
      ? await cloneConfiguration(modified)
      : await updateConfiguration(modified);

    return result.error
      ? result
      : {
          message: clone
            ? "Kopia konfiguracji została zapisana"
            : "Konfiguracja została zmieniona",
        };
  };

  const form = useForm({ shouldFocusError: true });

  return (
    <LoaderWrapper isLoading={!config}>
      {item && config && (
        <FormPopup
          popup={popup}
          form={form}
          onError={() => onInvalidForm()}
          onSubmit={onSubmit}
        >
          <Grid container direction="column" spacing={2}>
            <FormTextField
              form={form}
              name={"name"}
              label={"Nazwa"}
              value={config.name}
              disabled={true}
            />
            <FormTextField
              form={form}
              name={"type"}
              label={"Typ danych"}
              value={config.type}
              disabled={true}
            />
            <FormTextField
              form={form}
              name={"value"}
              label={"Wartość"}
              maxLength={65000}
              multiline={true}
              value={config.value}
            />
            <FormDatePicker
              form={form}
              name={"validFrom"}
              label={"Ważna od"}
              maxLength={50}
              required={true}
              rules={{ required: "Podaj wartość" }}
              value={moment(config.validFrom)}
            />
            <FormTextField
              form={form}
              name={"Notes"}
              label={"Notatki"}
              maxLength={65000}
              multiline={true}
              value={config.notes}
            />
          </Grid>
        </FormPopup>
      )}
    </LoaderWrapper>
  );
};
