import React from "react";
import {
  Add as AddIcon,
  Clear as ClearIcon,
  ContentCopy as ContentCopyIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  PlayArrow as ProcessIcon,
  UploadFile as UploadFileIcon
} from "@mui/icons-material";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
function Action(id, label, title, icon, onClick, permission, actionVisible) {
  return {
    permission: permission,
    label: label,
    title: title,
    icon: icon,
    id: id,
    onClick: onClick,
    actionVisible
  };
}

let GridActions = {
  Add: function (label, title, onClick, permission) {
    return {
      permission: permission,
      type: "grid",
      label: label,
      title: title,
      icon: <AddIcon className="AddIcon" />,
      id: "gridaction-add",
      onClick: onClick
    };
  },
  Upload: function (label, title, onClick, permission) {
    return {
      permission: permission,
      type: "grid",
      label: label,
      title: title,
      icon: <UploadFileIcon className="UploadIcon" />,
      id: "gridaction-upload",
      onClick: onClick
    };
  }
};

let RowActions = {
  PreviewDetails: function (
    onClick,
    permission,
    label = "Podglądnij",
    title = "Podgląd",
    id = "rowaction-details-preview-btn"
  ) {
    return {
      permission: permission,
      label: label,
      title: title,
      icon: <InfoIcon className="GridDetailsIcon" />,
      id: id,
      onClick: onClick
    };
  },

  Delete: function (title, formatText, onConfirm, permission) {
    return Action(
      "rowaction-delete",
      "Usuń",
      title,
      <ClearIcon className="GridDeleteIcon" />,
      arg => (
        <ConfirmDeleteDialog
          item={arg.item}
          data={arg.item}
          text={formatText(arg.item)}
          popup={arg.popup}
          onConfirm={onConfirm}
        />
      ),
      permission
    );
  },

  Process: function (title, formatText, onConfirm, permission) {
    return Action(
      "rowaction-process",
      "Wykonaj",
      title,
      <ProcessIcon className="GridProcessIcon" />,
      arg => (
        <ConfirmDeleteDialog
          item={arg.item}
          data={arg.item}
          text={formatText(arg.item)}
          popup={arg.popup}
          onConfirm={onConfirm}
        />
      ),
      permission,
      null
    );
  },

  Edit: function (title, onClick, permission) {
    return Action("rowaction-edit", "Edytuj", title, <EditIcon className="GridEditIcon" />, onClick, permission, null);
  },

  EmailForwardTo: function (label, title, onClick, permission, actionVisible = null) {
    return Action(
      "rowaction-emailforwardto",
      label,
      title,
      <ForwardToInboxIcon className="ForwardToInboxIcon" />,
      onClick,
      permission,
      actionVisible
    );
  },

  Clone: function (title, onClick, permission, actionVisible = null) {
    return Action(
      "rowaction-clone",
      "Klonuj",
      title,
      <ContentCopyIcon className="CloneIcon" />,
      onClick,
      permission,
      actionVisible
    );
  }
};

export { RowActions, GridActions, Action };
