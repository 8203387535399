import { FormControl, FormHelperText, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import FormWrapper from "./FormWrapper";

export const FormDatePicker = props => {
  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      defaultNull
      render={({ field: { onChange, value, ref }, fieldState: { error, invalid } }) => (
        <FormControl fullWidth required={props.required} error={!!error}>
          <TooltipWrapper text={props.helperText}>
            <div id={"formDatePicker-" + (props.id ?? props.name)} style={{ display: "inline-grid" }}>
              <DatePicker
                inputRef={ref}
                InputAdornmentProps={{ position: "end" }}
                disableFuture={props.disableFuture ?? false}
                //openTo="year"
                //views={["year", "month", "date"]}
                fullWidth
                variant="dialog"
                inputVariant="outlined"
                format="DD.MM.YYYY"
                required={props.required}
                disabled={props.disabled}
                margin="normal"
                name={props.name}
                label={props.label}
                value={value}
                onChange={onChange}
                style={props.style}
                renderInput={params => <TextField {...params} required={props.required} error={!!error} />}
              />
            </div>
          </TooltipWrapper>
          <FormHelperText>{error ? error.message : " "}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormDatePicker;
